var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"manage-developer-users"},[_c('div',{staticClass:"container fluid"},[_c('h1',{staticClass:"mx-2 my-3"},[_vm._v("Manage Users")]),_c('div',{staticClass:"flat card d-flex justify-content-end p-2 mb-1"},[_c('fd-button',{staticClass:"main",on:{"click":_vm.toAddPage}},[_c('i',{staticClass:"fas fa-plus mr-1"}),_vm._v("Add")]),_c('fd-button',{class:['bordered main  ml-2', { 'toggle active': _vm.isFilterActive }],on:{"click":function($event){$event.preventDefault();_vm.isFilterActive = !_vm.isFilterActive}}},[_c('i',{staticClass:"fas fa-filter"})])],1),_c('filter-panel',{on:{"clear":_vm.clearFilter,"filter":_vm.filterData},model:{value:(_vm.isFilterActive),callback:function ($$v) {_vm.isFilterActive=$$v},expression:"isFilterActive"}},[_c('div',{staticClass:"row p-2"},[_c('fd-input',{staticClass:"col-12 sm-col-6 md-col-4 px-1 mb-2",attrs:{"label":"Name","type":"text"},nativeOn:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.filterData($event)}},model:{value:(_vm.filter['name[partial]']),callback:function ($$v) {_vm.$set(_vm.filter, 'name[partial]', $$v)},expression:"filter['name[partial]']"}})],1)]),_c('vue-good-table',{staticClass:"card",attrs:{"mode":"remote","columns":_vm.tableColumns,"rows":_vm.tableData,"totalRows":_vm.tablePagination.total,"isLoading":_vm.isLoading,"pagination-options":{
        enabled: true,
        mode: 'pages',
        perPage: 30,
        perPageDropdown: _vm.perPageOptions,
        dropdownAllowAll: false
      },"sort-options":{
        enabled: false,
        initialSortBy: { field: 'createdAt', type: 'desc' }
      }},on:{"on-page-change":_vm.onPageChange,"on-per-page-change":_vm.onPerPageChange,"on-row-click":_vm.toDetail,"update:isLoading":function($event){_vm.isLoading=$event},"update:is-loading":function($event){_vm.isLoading=$event}},scopedSlots:_vm._u([{key:"table-row",fn:function(props){return [(props.column.field == 'actions')?_c('span',{on:{"click":function($event){$event.stopPropagation();}}},[_c('dropdown-button',{attrs:{"buttonClass":"circle flat"},scopedSlots:_vm._u([{key:"buttonContent",fn:function(){return [_c('i',{staticClass:"fas fa-ellipsis-v"})]},proxy:true}],null,true)},[_c('div',{staticClass:"card text-left"},[(_vm.isUserBannable(props.row.rolePermission))?_c('fd-list-item',{staticClass:"cursor-pointer unselectable",on:{"click":function($event){return _vm.toggleActiveStatus(props.row, !props.row.isActive)}}},[_c('i',{staticClass:"mr-1",class:props.row.isActive
                      ? 'fas fa-user-lock'
                      : 'fas fa-user-check'}),_vm._v(" "+_vm._s(props.row.isActive ? "Ban" : "Unban")+" ")]):_vm._e(),_c('fd-list-item',{staticClass:"cursor-pointer unselectable",on:{"click":function($event){return _vm.toEditPage(props.row.id)}}},[_c('i',{staticClass:"fas fa-pen mr-1"}),_vm._v(" Edit ")])],1)])],1):_vm._e(),(props.column.field == 'userStatus')?_c('div',[_c('chip',{class:[
              'fg-white',
              props.row.isActive ? 'bg-success' : 'bg-warn'
            ]},[_vm._v(" "+_vm._s(props.row.isActive ? "Active" : "Inactive")+" ")])],1):_c('span',[_vm._v(" "+_vm._s(props.formattedRow[props.column.field])+" ")])]}}])},[_c('div',{attrs:{"slot":"loadingContent"},slot:"loadingContent"},[_c('div',{staticClass:"d-flex justify-content-center"},[_c('spinner')],1)]),_c('div',{attrs:{"slot":"emptystate"},slot:"emptystate"},[_c('no-data',{attrs:{"message":"There is no user."}})],1)])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }