<template>
  <div class="manage-developer-users">
    <div class="container fluid">
      <h1 class="mx-2 my-3">Manage Users</h1>

      <div class="flat card d-flex justify-content-end p-2 mb-1">
        <fd-button class="main" @click="toAddPage"
          ><i class="fas fa-plus mr-1"></i>Add</fd-button
        >
        <fd-button
          :class="['bordered main  ml-2', { 'toggle active': isFilterActive }]"
          @click.prevent="isFilterActive = !isFilterActive"
        >
          <i class="fas fa-filter"></i>
        </fd-button>
      </div>

      <filter-panel
        v-model="isFilterActive"
        @clear="clearFilter"
        @filter="filterData"
      >
        <div class="row p-2">
          <fd-input
            v-model="filter['name[partial]']"
            class="col-12 sm-col-6 md-col-4 px-1 mb-2"
            label="Name"
            type="text"
            @keyup.enter.native="filterData"
          >
          </fd-input>
        </div>
      </filter-panel>

      <vue-good-table
        class="card"
        mode="remote"
        @on-page-change="onPageChange"
        @on-per-page-change="onPerPageChange"
        @on-row-click="toDetail"
        :columns="tableColumns"
        :rows="tableData"
        :totalRows="tablePagination.total"
        :isLoading.sync="isLoading"
        :pagination-options="{
          enabled: true,
          mode: 'pages',
          perPage: 30,
          perPageDropdown: perPageOptions,
          dropdownAllowAll: false
        }"
        :sort-options="{
          enabled: false,
          initialSortBy: { field: 'createdAt', type: 'desc' }
        }"
      >
        <div slot="loadingContent">
          <div class="d-flex justify-content-center">
            <spinner></spinner>
          </div>
        </div>
        <div slot="emptystate">
          <no-data message="There is no user."></no-data>
        </div>
        <!-- Table Buttons -->
        <template slot="table-row" slot-scope="props">
          <span v-if="props.column.field == 'actions'" @click.stop>
            <dropdown-button buttonClass="circle flat">
              <template #buttonContent>
                <i class="fas fa-ellipsis-v"></i>
              </template>
              <div class="card text-left">
                <fd-list-item
                  v-if="isUserBannable(props.row.rolePermission)"
                  class="cursor-pointer unselectable"
                  @click="toggleActiveStatus(props.row, !props.row.isActive)"
                >
                  <i
                    class="mr-1"
                    :class="
                      props.row.isActive
                        ? 'fas fa-user-lock'
                        : 'fas fa-user-check'
                    "
                  ></i>
                  {{ props.row.isActive ? "Ban" : "Unban" }}
                </fd-list-item>
                <fd-list-item
                  class="cursor-pointer unselectable"
                  @click="toEditPage(props.row.id)"
                >
                  <i class="fas fa-pen mr-1"></i>
                  Edit
                </fd-list-item>
              </div>
            </dropdown-button>
          </span>

          <div v-if="props.column.field == 'userStatus'">
            <chip
              :class="[
                'fg-white',
                props.row.isActive ? 'bg-success' : 'bg-warn'
              ]"
            >
              {{ props.row.isActive ? "Active" : "Inactive" }}
            </chip>
          </div>
          <span v-else>
            {{ props.formattedRow[props.column.field] }}
          </span>
        </template>
      </vue-good-table>
    </div>
  </div>
</template>

<script>
import vueGoodTable from "@/mixins/vue-good-table/mixin";
import { user as userAPI } from "@/api";

export default {
  components: {
    Spinner: () =>
      import("@/components/GlobalComponents/LoaderComponent/Spinner"),
    DropdownButton: () =>
      import("@/components/GlobalComponents/DropdownButton"),
    Chip: () => import("@/components/GlobalComponents/Chip"),
    FdListItem: () => import("@/components/GlobalComponents/List/FdListItem")
  },
  mixins: [vueGoodTable],
  props: {},
  data: function () {
    return {
      tableData: [],
      tablePagination: {},

      tableColumns: [
        {
          label: "",
          field: "actions",
          tdClass: "text-center",
          sortable: false,
          width: "100px"
        },
        {
          label: "Status",
          field: "userStatus"
        },
        {
          label: "Name",
          field: "name"
        },
        {
          label: "Role",
          field: "rolePermission.name"
        },
        {
          label: "Email",
          field: "user.email"
        },
        {
          label: "Contact No.",
          field: (row) => this.$getUserPhoneNumber(row.user)
        },
        {
          label: "Date Created",
          field: "createdAt",
          formatFn: (val) => this.$moment(val).format("DD-MM-YYYY")
        }
      ],
      tableParams: {
        page: 1,
        perPage: 30
      },
      isLoading: false,

      isFilterActive: false,
      filter: {
        "name[partial]": ""
      }
    };
  },
  computed: {},
  watch: {},
  created: function () {},
  beforeDestroy: function () {},
  mounted: function () {
    this.init();
  },
  methods: {
    async init() {
      await this.getUsers();
    },
    isUserBannable(role) {
      const OWNER_ROLE_ID = 1;
      return role.id != OWNER_ROLE_ID;
    },
    // ===================== Table related methods =====================
    updateParams(newProps) {
      this.tableParams = Object.assign({}, this.tableParams, newProps);
    },
    onPageChange(params) {
      this.updateParams({ page: params.currentPage });
      this.getUsers();
    },

    onPerPageChange(params) {
      this.$store.commit("setIsLoading", true);
      this.updateParams({ perPage: params.currentPerPage });
      this.getUsers().then(() => {
        this.$store.commit("setIsLoading", false);
      });
    },
    filterToggle() {
      this.isFilterActive = !this.isFilterActive;
    },
    toDetail(param) {
      let id = param.row.id;
      this.$router.push({
        name: "ManageUsersDetail",
        params: { id: id }
      });
    },
    toAddPage() {
      this.$router.push({
        name: "ManageUsersAdd"
      });
    },
    toEditPage(id) {
      this.$router.push({
        name: "ManageUsersEdit",
        params: {
          id: id
        }
      });
    },
    // ===================== Filter related methods =====================
    filterData() {
      this.tableParams.page = 1;
      // Trim filter
      this.filter = this._.mapValues(this.filter, (item) => item.trim());
      this.getUsers();
    },
    clearFilter() {
      this.tableParams.page = 1;
      this.filter = this._.mapValues(this.filter, () => "");
      this.$nextTick(() => {
        this.getUsers();
      });
    },
    // ================================== API RELATED ===============================
    async getUsers() {
      try {
        this.isLoading = true;
        let filteredParams = this.$cleanQueryParam(this.filter);
        let data = await userAPI.getUsers({
          queries: {
            ...filteredParams
          },
          page: this.tableParams.page,
          perPage: this.tableParams.perPage
        });

        this.tableData = this._.cloneDeep(data.data);
        this.tablePagination = this._.cloneDeep(data.meta.pagination);
        this.isLoading = false;
      } catch (error) {
        this.isLoading = false;
        this.$notify({
          group: "alert",
          type: "error",
          title: "Error",
          text: "Failed to get user list. Please try again later."
        });
        throw error;
      }
    },
    async toggleActiveStatus(user, statusToSet) {
      let actionLabel = statusToSet ? "unban" : "ban";
      let confirm = await this.$confirm({
        title: "Ban User",
        message: `Are you sure you want to ${actionLabel} user '${user.name}'?`
      });
      if (confirm) {
        try {
          this.$store.commit("setIsLoading", true);
          await userAPI.updateUserStatus(user.id, { isActive: statusToSet });
          this.$store.commit("setIsLoading", false);
          this.$notify({
            group: "alert",
            type: "success",
            title: "Success",
            text: `Status for user '${user.name}' has been updated.`
          });
          this.getUsers();
        } catch (error) {
          this.$store.commit("setIsLoading", false);
          this.$notify({
            group: "alert",
            type: "error",
            title: "Error",
            text: `Failed to ${actionLabel} user ${user.name}. Please try again later.`
          });
        }
      }
    }
  }
};
</script>

<style lang="scss"></style>
